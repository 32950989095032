import APIConfig from './api';

const TITLE = '';
const artistDetails = window.sessionStorage.getItem('artistDetails') && JSON.parse(window.sessionStorage.getItem('artistDetails'));

export default {
  LOGIN: `${TITLE} Login`,
  SIGNUP: `${TITLE} Sign up`,
  RESET_PASSWORD: `${TITLE} Reset password`,
  BASE_URL: process.env.PUBLIC_URL,
  profile_photo_base_url: `${APIConfig.hostname}/PortalUser/user/avatar`,
  profile_photo_base_url_activetone: `${APIConfig.hostname}/PortalContent/music/rbt/thumbnail`,
  AES_KEY: 'B!bZNWTM4X+mFIh2',
  AES_IV: '5sMTXymHI*Kc94a3',
  PUBLIC_KEY: 'MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEA0EDYKINnUVpNHbvbPVM9VDB70+mTXs76PxxBXrMAVVtM44YvRpMEqljtfmpmBvMb0UwOmO4oGoOslBfoHuNe4xl+9WPcwmVZtwy+gfzwruvRy8UTyeWPmd3cKffxnYZ3Jimr8iRbSrPY9dKWh3wDIiLiLu90gSiW3cNULrgA2jIzGXS4YYKWUrouzdaY8DrS2rT5RGc72bN0PXxVgzPWM7W4CjF60oCSK+GR6+3YcCJij7t47esRyh+Dykv40ceeZfFvfOLA6oOKNsA3jvZVczYdY0hHRuOZhs4tsrmY/EHxd/5KR16CR8Jk+NGb2D3OS6HfmzmkIjBFOEFHW924gQIDAQAB',
  SERVICEID: 2911,
  APPID: 1478,
  artistDetails
};

export const GOOGLE_KEY = 'AIzaSyBgMi_tKS5WRdH_RrCmdApIdCQCEZRIOyE';
